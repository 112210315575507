import { ref, onMounted } from 'vue';
import CreateMd5Hash from "~/packages/core/actions/hashing/CreateMd5Hash";
import {DevChangelogStore} from "~/store/DevChangelogStore";

interface ChangelogEntry {
    month: string;
    mdContent: string; // We keep markdown content here, which will be rendered directly in the component
}

const useChangelogLoader = () => {
    const changelogs = ref<ChangelogEntry[]>([]);
    const {getLastMonthHashed} = DevChangelogStore()

    const loadChangelogs = async (): Promise<void> => {
        // Use import.meta.glob with ?raw query to dynamically import markdown files as raw text
        const changelogFiles = import.meta.glob('/public/changelog/*.md', {
            query: '?raw',
            import: 'default',
        });

        // Map each file to its parsed content as markdown
        const changelogPromises = Object.entries(changelogFiles).map(async ([path, loader]) => {
            const content = await (loader as () => Promise<string>)();
            const filename = path.split('/').pop() || '';
            return {
                month: filename.replace('.md', ''),
                mdContent: content, // Store markdown content as-is
            };
        });

        changelogs.value = await Promise.all(changelogPromises);
        // Sort the changelogs in descending order by month
        changelogs.value.sort((a, b) => b.month.localeCompare(a.month));
    };

    const changelogUpdated = (): boolean => {
        return getLastMonthHashed() != CreateMd5Hash.execute(changelogs.value[0])
    }

    onMounted(loadChangelogs);

    return { changelogs, changelogUpdated };
};

// @ts-ignore
export default useChangelogLoader;
