import { defineStore } from 'pinia';

export const DevChangelogStore = defineStore('devChangeLogState', {
    state: () => {
        return {
            /**
             * last month encrypted changelog
             */
            lastMonthHashed: ''
        };
    },
    actions: {
        setLastMonthHashed(lastMonthHashed: string) {
            // @ts-ignore
            this.lastMonthHashed = lastMonthHashed
        },
        getLastMonthHashed(): string {
            // @ts-ignore
            return this.lastMonthHashed
        }
    },
    // @ts-ignore
    persist: {
        // @ts-ignore
        storage: persistedState.localStorage
    },
});



